import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router';
import './PageHeader.scss'

const PageHeader = ({ title, subnav = "", subtitle = "" }) => {
	const locationData = useLocation();
	const [url, setUrl] = React.useState("");

	useEffect(() => {
		setUrl(locationData.href.split("/").filter(item => item !== "").pop());
	},[locationData])
	
	return (
		<div className='pageheader-wrap'>
			<div className="pageheader-title">
				<h1>{title}</h1>
			</div>
			{
				{
					"": "",
					"about":
						<>
							<nav className='subnav about-nav mt-4 d-none d-lg-flex'>
								<Link to='/about/' activeClassName='active'>
									Our Church
								</Link>
								<Link to='/about/pastoral-team/' activeClassName='active'>
									Pastoral Team
								</Link>
								{/* <Link to='/about/life@ag' activeClassName='active'>
								Life @ FLAG
							</Link>
							<Link to='/about/get-connected' activeClassName='active'>
								Getting Connected
							</Link> */}
								<Link to='/about/timings/' activeClassName='active'>
									Church Timings
								</Link>
							</nav>
							<div className="small-accordioned d-block d-lg-none">
								<div
									className="accordions accordion-flush accordion_bg"
									id="accordionFlushExample"
								>
									<div className="accordion-item">
										<h2 className="accordion-header border p-4" id="flush-headingFour">
											<button
												className="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#flush-collapseFour"
												aria-expanded="false"
												aria-controls="flush-collapseFour"
											>
												{
													{
														"about": <span>Our Church</span>,
														"pastoral-team": <span>Pastoral Team</span>,
														"timings": <span>Church Timings</span>
													}[url]
												}
											</button>
										</h2>
										<div
											id="flush-collapseFour"
											className="accordion-collapse collapse"
											aria-labelledby="flush-headingFour"
											data-bs-parent="#accordionFlushExample"
										>
											<div className="accordion-body">
												<div className="contents">
													{url !== "about" ? (
														<span>
															<Link to="/about/" className="nav-link">
																Our Church
															</Link>
														</span>
													) : (
														""
													)}
													{url !== "pastoral-team" ? (
														<span>
															<Link to="/about/pastoral-team/" className="nav-link">
																Pastoral Team
															</Link>
														</span>
													) : (
														""
													)}

													{url !== "timings" ? (
														<span>
															<Link to="/about/timings/" className="nav-link">
																Church Timings
															</Link>
														</span>
													) : (
														""
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>,
					"announcements":
						<nav className='subnav mt-4'>
							<Link className='me-2' to='/announcements/'>Announcements</Link>
							{' > '} &nbsp; &nbsp; &nbsp;
							<div className='me-2'>{subtitle}</div>
						</nav>,
					"events":
						<nav className='subnav mt-4'>
							<Link className='me-2' to='/events/'>Events</Link>
							{' > '} &nbsp; &nbsp; &nbsp;
							<div className='me-2'>{subtitle}</div>
						</nav>,
				}[subnav]
			}
		</div>
	)
}

export default PageHeader;