import React from 'react';
import MainLayout from '../../layouts/mainlayout/MainLayout';
import PageHeader from '../../components/pageheader/PageHeader';

function About() {
	return (
		<MainLayout title="About Us">
			<PageHeader title="About Us" subnav="about" />
			<section className='about-section'>
				<h3 className='section-head'>History</h3>
				<p>
					Thiruverkadu, a well-known pilgrim center to the People of Chennai is a rapidly growing village sited 15 km away from the city. We can see beautiful temples in whichever direction we turn small or big. But hardly can we see any churches here.
					With the heartfelt need Rev. D. Mohan, chief pastor of New Life A. G. Church and General Superintendent of All India Assemblies of God sent Pr. N. Johnson to this village to pioneer a church in October 1998.
				</p>
				<p>
					The task was not an easy one. Since about 99% of the population are Hindus, and most of them are slum dwellers living in the bondage of Satan, sin, and sickness. The first Sunday service was conducted in a rented house with two people on 7th November 1998.

				</p>
				<p>
					Pr. Johnson faced severe opposition to conduct prayer from the neighborhood and some local people even threatened to kill him. Amid all these trials and tribulations, God began to touch the souls and within a year the house church couldn't contain the gathering of about 30 people.
					Now church began to struggle to find a new place for worship as the doors were shut for Christians. After much prayer and efforts, the Church moved to a rented thatched shed in the year 2000. And within two years this place was also filled with about 120 souls, by the grace of God.
				</p>
				<p>
					Then, God enabled the FLAG to buy a piece of land in the year 2003 and built a hall with a light-roof. There the congregation was doubled and two services were conducted. But in the year 2005 December 23rd, the building was demolished because of a government scheme and the plot of the church was merged with the river, But the faithful Lord miraculously provided the present church building in the year 2008. Since then the church is growing faster by the grace of God.
				</p>
			</section>
		</MainLayout>
	);
}
export default About;